import React from "react"
import { Nav } from "react-bootstrap"
import { route } from "../../../constants/route"
import { Link } from "gatsby"


export default function HeaderNav({ isOffCanvas, isAuth, handleClose }) {
  const scrollToAnchor = (e, id) => {
    e.preventDefault()

    const targetElement = document.getElementById(id)
    if (targetElement) {
      const targetElementTop = targetElement.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({
        top: targetElementTop - 100,
        behavior: "smooth"
      })
    }
  }
  const onClickHandler = (e, id) => {
    scrollToAnchor(e, id)
    handleClose && handleClose()
  }

  return (
    <Nav
      className={`${isAuth ? "d-flex flex-row" : isOffCanvas ? "d-flex d-none-flex flex-column" : "d-lg-flex d-none flex-row"}   gap-sm-5 gap-3  `}>
      {isAuth ? <>
          <Link className={"nav-link"} to={route.connectios}>Связка</Link>
          <Link className={"nav-link"} to={route.tariff}>Тарифы</Link>
        </>
        : <><Link className={"nav-link"} to="/#ability" onClick={(e) => onClickHandler(e, "ability")}>О
          платформе</Link>
          <Link className={"nav-link"} to="/#link"
                onClick={(e) => onClickHandler(e, "link")}>Тарифы</Link>
          <Link className={"nav-link"} to="/#dataBase"
                onClick={(e) => onClickHandler(e, "dataBase")}>База
            знаний</Link></>}
    </Nav>
  )
}

