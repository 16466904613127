export const route = {
  home: "/",
  recoveryPassword: "/auth/recovery",
  confirmRecoveryPassword: "/auth/recovery/confirm",
  registration: "/auth/registration",
  login: "/auth/login",
  error: "/404",
  profile: "/profile",
  auth: "auth",
  connectios: "/connections",
  connectiosCreate: "/connections/create",
  connectiosEdit: "/connections/edit",
  tariff: "/tariff",
  tariffCheck: "/tariff/check",
  tariffPayment: "/tariff/payment",
  integrationCreate: "/integrations/create",
  integrationEdit: "/integrations/edit",
  filterForCopy: "/integrations/filterForCopy",
  filterForIntegration: "/integrations/filterForIntegration",
  filterForIntegrationCreate: "/integrations/filterForIntegration/create",
  filterForIntegrationEdit: "/integrations/filterForIntegration/edit",
  politics: "/politics",
  term: "/term",
  baseUrl: "https://adapter.nolim.cc",
}
