import React, { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const StaticDataContext = createContext("")

const StaticDataContextProvider = ({ children }) => {
  const tariffsData = useStaticQuery(graphql`
    query {
      fetch {
        tariff {
          items {
            days
            id
            integrations
            price
            title
          }
        }
      }
    }
  `)
  const tariffs = tariffsData.fetch.tariff.items

  const value = {
    tariffs,
  }

  return (
    <StaticDataContext.Provider value={value}>
      {children}
    </StaticDataContext.Provider>
  )
}
export default StaticDataContextProvider
